<template>
  <main class="min-h-screen px-4">
    <div class="grid justify-content-center text-8xl">QRC</div>
    <router-view />
  </main>
</template>
<style lang="scss">
body {
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-b);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
