import { API_ENDPOINT, fetchOPT } from '../config/config';

export default {
    fetch(url, opt={}){
        url = API_ENDPOINT + url;

        return fetch(url, Object.assign({}, fetchOPT, opt))
            .then(this.response);
    },
    get(url) {
        return this.fetch(url);
    },
    post(url, params) {
        return this.fetch(url,{method:'POST', body: this.body(params)});
    },
    delete(url, params) {
        return this.fetch(url,{method:'DELETE', body: this.body(params)});
    },
    patch(url, params) {
        return this.fetch(url,{method:'PATCH', body: this.body(params)});
    },
    put(url, params) {
        return this.fetch(url,{method:'PUT', body: this.body(params)});
    },
    body(params){
        const data = new FormData();
        if (typeof params !== 'undefined') {
            for(const i in params) {
                if (Array.isArray(params[i])) {
                    if (params[i].length > 0)params[i].forEach(v=>{data.append(`${i}[]`, v);});
                    else data.append(i, []);
                }
                else if (typeof params[i] === 'object') {
                    Object.keys(params[i]).forEach(key => { data.append(`${i}[${key}]`, params[i][key]); });
                }
                else data.append(i, params[i]);
            }
        }
        return data;
    },
    response(response) {
        return response.json();
    }
};