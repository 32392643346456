<template>
  <div>
    <Toast />
    <div class="grid justify-content-center">
      <div class="col-12 md:col-4">
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <BlockUI :blocked="loading">
            <div class="field hidden md:block">
              <div class="p-inputgroup">
                <InputText
                  placeholder="Link"
                  id="linkDesktop"
                  v-model="v$.link.$model"
                  :class="{ 'p-invalid': v$.link.$invalid && submitted }"
                />
                <Button type="submit" label="Qısalt" />
              </div>
              <small
                v-if="
                  (v$.link.$invalid && submitted) || v$.link.$pending.$response
                "
                class="p-error"
                >{{ v$.link.required.$message.replace("Value", "Link") }}</small
              >
            </div>
            <div class="md:hidden">
              <div class="field">
                <InputText
                  placeholder="Link"
                  id="linkMobile"
                  v-model="v$.link.$model"
                  :class="{
                    'p-invalid': v$.link.$invalid && submitted,
                    'p-inputtext-lg': true,
                  }"
                />
                <small
                  v-if="
                    (v$.link.$invalid && submitted) ||
                    v$.link.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.link.required.$message.replace("Value", "Link")
                  }}</small
                >
              </div>
              <Button type="submit" label="Qısalt" class="mt-2 p-button-lg" />
            </div>
          </BlockUI>
        </form>
      </div>
    </div>
    <div
      class="grid flex-column justify-content-center align-content-center pt-3"
      v-if="loading"
    >
      <div class="text-center text-3xl">
        <Skeleton class="mb-2"></Skeleton>
      </div>
      <br />
      <div>
        <Skeleton size="250px" class="inline-block"></Skeleton>
      </div>
    </div>

    <div
      class="grid flex-column justify-content-center align-content-center pt-3"
      v-if="showQR"
    >
      <div class="text-center text-3xl">
        <a :href="qrLink" class="no-underline text-primary" target="_blank">{{
          qrLink
        }}</a
        ><Button
          @click.prevent="copyClipboard"
          icon="pi pi-copy"
          class="p-button-rounded p-button-text"
        />
      </div>
      <br />
      <div>
        <Image
          :src="'data:image/png;base64,' + qrImage"
          alt="Image"
          width="250"
          preview
        />
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Image from "primevue/image";
import Skeleton from "primevue/skeleton";
import BlockUI from "primevue/blockui";
import { reactive, ref } from "vue";
import { url, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import api from "../util/api";

export default {
  name: "Home",
  setup() {
    const state = reactive({
      link: "",
    });

    const rules = {
      link: { required, url },
    };

    const submitted = ref(false);
    const loading = ref(false);
    const showQR = ref(false);
    const qrLink = ref("");
    const qrImage = ref("");

    const v$ = useVuelidate(rules, state);
    const toast = useToast();
    const copyClipboard = () => {
      navigator.clipboard.writeText(qrLink.value);
      toast.add({ severity: "success", summary: "Link copied" });
    };
    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid || loading.value) {
        return;
      }
      showQR.value = false;
      loading.value = true;
      qrLink.value = "";
      qrImage.value = "";

      api.post("/link/create", { url: state.link }).then((r) => {
        loading.value = false;
        if (r.status) {
          qrLink.value = r.url;
          qrImage.value = r.qr;
          showQR.value = true;
          resetForm();
        }
      });
    };
    const resetForm = () => {
      state.link = "";
      submitted.value = false;
    };

    return {
      state,
      v$,
      handleSubmit,
      submitted,
      showQR,
      qrLink,
      qrImage,
      copyClipboard,
      loading,
    };
  },
  components: {
    InputText,
    Button,
    Image,
    Skeleton,
    BlockUI,
  },
};
</script>
<style lang="scss" scoped></style>
